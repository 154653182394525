import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = ({ color }) => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <rect
            fill="none"
            height="336"
            rx="57"
            ry="57"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="32"
            width="336"
            x="128"
            y="128"
        />
        <path
            d="m383.5 128 .5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
        />
    </svg>
);

CopyIcon.propTypes = {
    color: PropTypes.string,
};

export default CopyIcon;
