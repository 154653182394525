import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import styled from 'libs/styled';
import SimpleCheckbox from 'components/SimpleCheckbox';
import DropDown from 'components/TextGenerator/DropDown';
import CopyIcon from '../../assets/icons/Copy';

const Div = styled('div')``;

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Label = styled('label')`
    margin-bottom: 4px;
`;

const ButtonWrapper = styled('div')``;
//eslint-disable-next-line
const GeneratorInput = ({ text, setSpec, setWords, ...rest }) => {
    const NumberOfWordsList = [250, 500, 750, 1000, 1500, 2000, 2500, 3000];
    const NumberOfParagraphsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }, [copied]);

    const tagList = ['p', 'span', 'h2', 'h3', 'h4', 'h5', 'h6'];

    const { startWithLorem, withHtml, tag } = rest;

    return (
        <Div
            alignItems="center"
            backgroundColor="var(--color-secondaryBackground)"
            borderRadius="20px 20px 0 0"
            display="flex"
            justifyContent="space-between"
            marginTop="24px"
            padding="16px 24px"
        >
            <Div display="flex" flexDirection={['column', null, null, 'row']} gap="16px">
                <Div display="flex" flexDirection={['column', 'row', null]} gap="16px">
                    <Div display="flex" flexDirection={['row', null, null, 'row']} gap="16px">
                        <Column>
                            <Label>Words:</Label>
                            {typeof window !== 'undefined' && (
                                <DropDown
                                    option="numberOfWords"
                                    optionsList={NumberOfWordsList}
                                    setFunc={setWords}
                                    {...rest}
                                />
                            )}
                        </Column>
                        <Column>
                            <Label>Paragraphs:</Label>
                            {typeof window !== 'undefined' && (
                                <DropDown
                                    option="numberOfParagraphs"
                                    optionsList={NumberOfParagraphsList}
                                    setFunc={setSpec}
                                    {...rest}
                                />
                            )}
                        </Column>
                    </Div>
                    <Div>
                        <Column gap="8px" justifyContent="space-between" marginTop="-3px">
                            <SimpleCheckbox
                                defaultValue={startWithLorem}
                                option="startWithLorem"
                                setSpec={setSpec}
                                {...rest}
                                label="Start with Lorem ipsum..."
                            />
                            <SimpleCheckbox
                                defaultValue={withHtml}
                                option="withHtml"
                                setSpec={setSpec}
                                {...rest}
                                label="Add HTML tags:"
                            />
                        </Column>
                    </Div>
                </Div>

                {withHtml && (
                    <Column>
                        <Label>Tags</Label>
                        {typeof window !== 'undefined' && (
                            <DropDown option="tag" optionsList={tagList} setFunc={setSpec} {...rest} />
                        )}
                    </Column>
                )}
            </Div>

            <ButtonWrapper onClick={() => setCopied(!copied)}>
                <CopyToClipboard text={text}>
                    <button
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: '8px',
                            zIndex: '9',
                            backgroundColor: '#2d915a',
                            borderRadius: '4px',
                            padding: '8px 16px',
                            fontSize: '16px',
                            color: '#fff',
                            outline: 'none',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        type="button"
                    >
                        <Div alignItems="center" display="flex" width="25px">
                            <CopyIcon color="#fff" />
                        </Div>
                        {copied ? 'Got it!' : 'Copy'}
                    </button>
                </CopyToClipboard>
            </ButtonWrapper>
        </Div>
    );
};

GeneratorInput.propTypes = {
    inputValue: PropTypes.any,
    setInputValue: PropTypes.any,
    setSpec: PropTypes.any,
    setStartWithLorem: PropTypes.any,
    setTag: PropTypes.any,
    setwithHtml: PropTypes.any,
    tag: PropTypes.any,
    text: PropTypes.object,
    withHtml: PropTypes.string,
};

export default GeneratorInput;
