import { allWords } from './constants';

const shuffle = array => {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

export const generateText = spec => {
    const { numberOfWords } = spec;
    let totalWords = allWords;

    // Append all words to itself until if big enough for the requested amount of words
    while (numberOfWords > totalWords.length) {
        totalWords = totalWords.concat(allWords);
    }

    // Slice array into the requested amount of words
    totalWords = totalWords.slice(0, numberOfWords);

    // Convert array to string
    totalWords = shuffle(totalWords).join(' ');

    return totalWords;
};

const removeTags = str => str.replace(/<[^>]*>/g, '');

const wrapParagraphsInTag = (paragraphs, tag) => paragraphs.map(paragraph => `<${tag}>${paragraph}</${tag}>`);

const removePeriods = input => {
    if (Array.isArray(input)) {
        return input.map(string => string.replace(/\./g, ''));
    }
    return input.replace(/\./g, '');
};

const capitalizeParagraphs = (paragraphs, tag) => {
    // Initialize an empty array to store the capitalized paragraphs
    let capitalizedParagraphs = [];

    const textHasTags = paragraphs[0].includes('<');

    if (textHasTags) {
        paragraphs = paragraphs.map(removeTags);
    }

    paragraphs = paragraphs.map(removeTags);

    // Iterate over the paragraphs and capitalize the first letter and add a period at the end
    paragraphs.forEach(paragraph => {
        // Capitalize the first letter
        const capitalizedParagraph = paragraph.charAt(0).toUpperCase() + paragraph.slice(1);

        capitalizedParagraphs.push(capitalizedParagraph);
    });

    if (textHasTags) {
        capitalizedParagraphs = wrapParagraphsInTag(capitalizedParagraphs, tag);
    }

    return capitalizedParagraphs;
};

const addLoremIpsum = paragraphs => {
    // Check if the first paragraph starts with "Lorem ipsum dolor sit amet"
    const startsWithLoremIpsum = paragraphs[0].startsWith('Lorem ipsum dolor sit amet,');

    // If the first paragraph doesn't start with "Lorem ipsum dolor sit amet," replace the first five words with it
    if (!startsWithLoremIpsum) {
        // Split the first paragraph into an array of words
        const words = paragraphs[0].split(' ');

        // Replace the first five words with "Lorem ipsum dolor sit amet"
        words[0] = 'Lorem';
        words[1] = 'ipsum';
        words[2] = 'dolor';
        words[3] = 'sit';
        words[4] = 'amet,';

        // Join the words back into a single string
        paragraphs[0] = words.join(' ');
    }

    return paragraphs;
};

const replaceLoremIpsum = str => {
    // Check if the string starts with "lorem ipsum dolor sit amet,"

    if (str.startsWith('lorem ipsum dolor sit amet,')) {
        // Split the string into an array of words
        const words = str.split(' ');

        // Remove the first 5 words
        const restWords = words.slice(5);

        // Generate 5 random numbers between 0 and the length of the array
        const randomIndices = [...Array(5)].map(() => Math.floor(Math.random() * restWords.length));

        const newWords = [];

        randomIndices.forEach((index, i) => {
            newWords[i] = restWords[index];
        });

        // Join the array of words back into a string and return it
        const result = restWords.concat(newWords).join(' ');

        return result;
    }

    // If the string doesn't start with "lorem ipsum dolor sit amet,", return the original string
    return str;
};

//Add periods in order to create random sentences. 5% of the words will be followed by a period.
const addRandomPeriods = inputStrings =>
    inputStrings.map(inputString => {
        const paragraphs = inputString.split('\n');
        for (let i = 0; i < paragraphs.length; i++) {
            const words = paragraphs[i].split(' ');
            for (let j = 0; j < words.length; j++) {
                if (Math.random() < 0.09 && words[j].charAt(words[j].length - 1) !== '.') {
                    words[j] += '.';
                    if (j + 1 < words.length) {
                        words[j + 1] = words[j + 1].charAt(0).toUpperCase() + words[j + 1].slice(1);
                    }
                }
            }
            paragraphs[i] = words.join(' ');
            if (paragraphs[i].charAt(paragraphs[i].length - 1) !== '.') {
                paragraphs[i] += '.';
            }
        }
        return paragraphs.join('\n');
    });

export const divideTextIntoParagraphs = (spec, text) => {
    const { numberOfParagraphs, startWithLorem, withHtml, tag } = spec;
    // Check if text is an array. If it is, join the elements into a single string.
    if (Array.isArray(text)) {
        text = text.join(' ');
    }

    //Remove tags
    text = removeTags(text);

    // Lowercase the text
    text = text.toLowerCase();

    // Remove preiods
    text = removePeriods(text);

    // Remove Lorem ipsum...
    text = replaceLoremIpsum(text);

    // Split the text into an array of words
    const words = text.split(' ');

    // Calculate the length of each paragraph
    const lengthPerParagraph = Math.ceil(words.length / numberOfParagraphs);

    // Initialize an empty array to store the paragraphs
    let paragraphs = [];

    // Initialize a counter to keep track of the number of words added to each paragraph
    let wordCounter = 0;

    // Initialize a variable to store the current paragraph
    let currentParagraph = '';

    // Iterate over the words and add them to the paragraphs
    words.forEach(word => {
        // If the word counter has reached the desired length, push the paragraph to the array and reset the variables
        if (wordCounter === lengthPerParagraph) {
            paragraphs.push(currentParagraph.trim());
            currentParagraph = '';
            wordCounter = 0;
        }

        // Add the word to the current paragraph and update the word counter
        currentParagraph += `${word} `;
        wordCounter++;
    });

    // Push the remaining text into the array
    paragraphs.push(currentParagraph.trim());

    paragraphs = addRandomPeriods(paragraphs);

    if (startWithLorem) {
        paragraphs = addLoremIpsum(paragraphs);
    }

    if (withHtml) {
        paragraphs = wrapParagraphsInTag(paragraphs, tag);
    }

    paragraphs = capitalizeParagraphs(paragraphs, tag);

    return paragraphs;
};
