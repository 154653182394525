import { useEffect, useRef, useState } from 'react';
import styled from 'libs/styled';
import GridWrapper from '../wrappers/GridWrapper';
import GeneratorInput from './GeneratorInput';
import GeneratorOutput from './GeneratorOutput';
import { generateText, divideTextIntoParagraphs } from './generateText';

const Div = styled('div')``;

const TextGenerator = () => {
    const defaultWords = { numberOfWords: 250 };
    const defaultSpec = {
        numberOfParagraphs: 5,
        startWithLorem: true,
        withHtml: false,
        tag: 'p',
    };

    const [words, setWords] = useState({ ...defaultWords });
    const [spec, setSpec] = useState({ ...defaultSpec });

    const [text, setText] = useState(generateText(words)); //Testing out OBS in order to make som cool twitter videos.
    //Using ref to track lifeCycle and only run the effect after the first render
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) {
            setText(divideTextIntoParagraphs(spec, text));
        } else {
            didMount.current = true;
        }
    }, [spec]);

    //Update text only when words change
    useEffect(() => {
        const newText = generateText(words);
        setText(divideTextIntoParagraphs(spec, newText));
    }, [words]);

    return (
        <GridWrapper>
            <Div gridColumn={['1 / span 12', '2 / span 10', null, null, null, '3 / span 6']} zIndex="2">
                <GeneratorInput {...spec} setSpec={setSpec} setWords={setWords} {...words} text={text} />
                <GeneratorOutput {...spec} text={text} />
            </Div>
        </GridWrapper>
    );
};

export default TextGenerator;
