import React from 'react';
import PropTypes from 'prop-types';
import styled from 'libs/styled';

const Div = styled('div')`
    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const GeneratorOutput = props => {
    let content;

    if (typeof props.text === 'string') {
        content = <p>{props.text}</p>;
    } else {
        content = props.text.map((paragraph, index) => <p key={index}>{paragraph}</p>);
    }

    return (
        <Div
            backgroundColor="#cbd2d9"
            borderRadius="0 0 20px 20px"
            color="black"
            cursor="text"
            maxHeight="50vh"
            overflow="scroll"
            padding="48px 24px"
        >
            {content}
        </Div>
    );
};

GeneratorOutput.propTypes = {
    text: PropTypes.array,
};

export default GeneratorOutput;
