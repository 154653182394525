import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'libs/styled';

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
`;

const Container = styled('div')`
    position: relative;
    width: 75px;
`;

const Button = styled('button')`
    border: 0;
    border-radius: 4px;
    background: var(--color-tertiaryBackground);
    color: var(--color-text);
    width: 100%;
    padding: 6px 8px;
    position: relative;
    text-align: left;
    font-size: 16px;

    :hover {
        cursor: pointer;
    }

    :after {
        position: absolute;
        right: 6px;
        top: 4px;
    }
}
`;

const Options = styled('ul')`
    position: absolute;
    width: 100%;
    background-color: var(--color-tertiaryBackground);
    display: none;
    list-style: none;
    padding: 4px 0;
    margin-top: -4px;
    border-radius: 0 0 4px 4px;

    ${({ show }) => show && 'display: block'}
`;

const Items = styled('li')`
    padding: 6px 10px;

    :active,
    :focus,
    :hover {
        background: var(--color-background);
        cursor: pointer;
    }
`;

const CustomSelect = ({ option, optionsList, setFunc, ...rest }) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);

    const [selectedOption, setSelectedOption] = useState(rest[option]);

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const setSelectedThenCloseDropdown = index => {
        setSelectedOption(optionsList[index]);
        setIsOptionsOpen(false);
        if (option === 'numberOfWords') {
            setFunc({ numberOfWords: optionsList[index] });
        } else if (option === 'tag') {
            setFunc({ ...rest, tag: optionsList[index] });
        } else {
            setFunc({ ...rest, numberOfParagraphs: optionsList[index] });
        }
    };

    const handleKeyDown = index => e => {
        switch (e.key) {
            case ' ':
            case 'SpaceBar':
            case 'Enter':
                e.preventDefault();
                setSelectedThenCloseDropdown(index);
                break;
            default:
                break;
        }
    };

    const handleListKeyDown = e => {
        switch (e.key) {
            case 'Escape':
                e.preventDefault();
                setIsOptionsOpen(false);
                break;
            case 'ArrowUp':
                e.preventDefault();
                setSelectedOption(selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1);
                break;
            case 'ArrowDown':
                e.preventDefault();
                setSelectedOption(selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1);
                break;
            default:
                break;
        }
    };

    return (
        <Wrapper>
            <Container>
                <Button
                    aria-expanded={isOptionsOpen}
                    aria-haspopup="listbox"
                    className={isOptionsOpen ? 'expanded' : ''}
                    type="button"
                    onClick={toggleOptions}
                    onKeyDown={handleListKeyDown}
                >
                    {selectedOption}
                </Button>
                <Options
                    aria-activedescendant={optionsList[selectedOption]}
                    role="listbox"
                    show={isOptionsOpen}
                    tabIndex={-1}
                    onKeyDown={handleListKeyDown}
                >
                    {optionsList.map((option, index) => (
                        <Items
                            aria-selected={selectedOption === index}
                            id={option}
                            key={index}
                            role="option"
                            tabIndex={0}
                            onClick={() => {
                                setSelectedThenCloseDropdown(index);
                            }}
                            onKeyDown={handleKeyDown(index)}
                        >
                            {option}
                        </Items>
                    ))}
                </Options>
            </Container>
        </Wrapper>
    );
};

CustomSelect.propTypes = {
    handleChange: PropTypes.func,
    option: PropTypes.string,
    optionsList: PropTypes.shape({
        length: PropTypes.number,
        map: PropTypes.func,
    }),
    setFunc: PropTypes.func,
    setSpec: PropTypes.func,
    setWords: PropTypes.func,
};

export default CustomSelect;
