import React from 'react';
import styled from 'libs/styled';
import Layout from 'components/Layout';
import TextGeneratror from 'components/TextGenerator';
import { above } from 'components/config/mediaqueries';
import { Link } from 'gatsby';
import GridWrapper from '../components/wrappers/GridWrapper';
import useNav from '../hooks/useNav';

const Div = styled('div')``;

const PageWrapper = styled('div')`
    padding-bottom: 120px;
    background: var(--color-tertiaryBackground);
    transition: background 0.1s ease-in-out;
`;

const TextSection = styled('section')`
    line-height: 1.5;
    font-family: 'Roboto';
    padding-bottom: 80px;
    background: var(--color-tertiaryBackground);
    transition: background 0.1s ease-in-out;
`;

const TextHeading = styled('h2')`
    font-family: 'Roboto';
    font-size: 42px;
    margin-bottom: 16px;
`;

const HeroSection = styled('section')`
    line-height: 1.5;
    grid-column: 2 / span 6;
    font-family: 'Roboto';
    padding: 0 24px;
    height: calc(100vh - 96px);

    background: var(--color-background);
    transition: background 0.1s ease-in-out;

    ${above.tabletSm} {
        height: calc(100vh - 118px);
        grid-column: 2 / span 10;
        grid-row: unset;
    }
    ${above.desktopLg} {
        grid-column: 3 / span 8;
    }

    p {
        margin: 0;
        margin-bottom: 12px;
        font-size: 14px;

        ${above.tabletSm} {
            font-size: 16px;
        }
        ${above.desktopLg} {
            font-size: 18px;
        }
    }

    h1 {
        font-family: 'Roboto';
        font-size: 18px;
        margin-bottom: 32px;

        ${above.tabletSm} {
            font-size: 24px;
        }
        ${above.desktopLg} {
            font-size: 32px;
        }
    }
`;

const TextWrapper = styled('div')`
    height: 100%;

    p {
        margin: 0;
        margin-bottom: 12px;
        font-size: 14px;

        ${above.tabletSm} {
            font-size: 16px;
        }
        ${above.desktopLg} {
            font-size: 18px;
        }
    }
`;

const ContentWrapper = styled('div')`
    padding: 0 24px;
`;

const Waveshapes = styled('div')`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    z-index: 0;
    margin-bottom: -60px;

     {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    svg {
        position: relative;
        display: block;
        width: calc(288% + 1.3px);
        height: 404px;
        transform: rotateY(180deg);
    }

    .shape-fill {
        fill: var(--color-tertiaryBackground);
    }
`;

const ScrollWrapper = () => {
    const generatorRef = useNav('Generator');
    const whatRef = useNav('What');
    const whyRef = useNav('Why');

    return (
        <Div>
            <PageWrapper>
                <HeroSection id="generatorContainer" ref={generatorRef}>
                    <Div display="flex" flexDirection="column" height="100%">
                        <TextGeneratror />
                    </Div>
                    <Waveshapes>
                        <svg
                            data-name="Layer 1"
                            preserveAspectRatio="none"
                            viewBox="0 0 1200 120"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="shape-fill"
                                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                                opacity=".25"
                            />
                            <path
                                className="shape-fill"
                                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                                opacity=".5"
                            />
                            <path
                                className="shape-fill"
                                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                            />
                        </svg>
                    </Waveshapes>
                </HeroSection>
                <ContentWrapper id="whyContainer" ref={whyRef}>
                    <TextSection marginTop="80px">
                        <GridWrapper>
                            <TextHeading gridColumn={['1 / span 12', '2 / span 10', '3 / span 6']} gridRow="1">
                                Why should I use Lorem ipsum text?
                            </TextHeading>
                            <TextWrapper
                                fontSize={['16px', null, '18px']}
                                gridColumn={['1 / span 12', '2 / span 10', '3 / span 5']}
                                gridRow="2"
                                marginBottom="40px"
                            >
                                <p>
                                    Do you know WHY you're suppose to use this lattin gibberish? You might be surprised
                                    to learn that there are actually several benefits to using this type of placeholder
                                    text.
                                </p>
                                &nbsp;
                                <ol>
                                    <li>
                                        <strong>Focus on Design:</strong> By using Lorem ipsum text, you can focus on
                                        the design of your website or other creative project without being distracted by
                                        the actual text. This allows you to create a layout that is visually appealing
                                        and easy to navigate, without worrying about the content itself.
                                    </li>
                                    &nbsp;
                                    <li>
                                        <strong>Save Time:</strong> Writing text from scratch can be time-consuming,
                                        especially if you're working on a large project with multiple pages. Using Lorem
                                        ipsum text allows you to quickly fill in the spaces where text is needed, saving
                                        you time and effort.
                                    </li>
                                    &nbsp;
                                    <li>
                                        <strong>SEO Optimization:</strong> Did you know that using filler text can
                                        actually be good for your website's SEO? Search engines like Google give higher
                                        rankings to websites with more content, so using Lorem ipsum text can help boost
                                        your site's search engine visibility.
                                    </li>
                                </ol>
                                &nbsp;
                                <p>
                                    Overall, Lorem ipsum text is a useful tool for designers, developers, and content
                                    creators alike. Try it out for yourself and see how it can simplify your workflow
                                    and improve the overall quality of your work.
                                </p>
                            </TextWrapper>
                        </GridWrapper>
                    </TextSection>

                    <TextSection id="whatContainer" marginBottom="140px" padding="120px 0" ref={whatRef}>
                        <GridWrapper>
                            <TextHeading gridColumn={['1 / span 12', '2 / span 10', '3 / span 8']} gridRow="1">
                                What is lorem ipsum text and where does it come from?
                            </TextHeading>
                            <TextWrapper
                                fontSize={['16px', null, '18px']}
                                gridColumn={['1 / span 12', '2 / span 10', '3 / span 5']}
                            >
                                <p>
                                    Lorem ipsum text is a type of placeholder text that is commonly used in design,
                                    printing, and typesetting. It's used as a stand-in for actual text in the early
                                    stages of a design project, before the final content is available.
                                </p>
                                &nbsp;
                                <p>
                                    The text itself is nonsensical and doesn't have any real meaning. It consists of a
                                    jumbled sequence of Latin words and phrases, which are deliberately chosen to be as
                                    meaningless as possible.
                                </p>
                                &nbsp;
                                <p>
                                    The origins of Lorem ipsum text can be traced back to the 1500s, when an unknown
                                    printer took a sample of Latin text and scrambled it to create a type specimen book.
                                    The resulting text was popularized in the 1960s by the Letraset company, which used
                                    it in their typesetting products.
                                </p>
                                &nbsp;
                                <p>
                                    Today, Lorem ipsum text is a standard placeholder text used in the design industry,
                                    and it's familiar to designers and developers worldwide.
                                </p>
                            </TextWrapper>
                        </GridWrapper>
                    </TextSection>
                </ContentWrapper>
            </PageWrapper>
        </Div>
    );
};

const IndexPage = () => (
    <Layout>
        <ScrollWrapper />
    </Layout>
);
export default IndexPage;
