import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'libs/styled';

const CheckboxWrapper = styled('label')`
    display: flex;
    cursor: pointer;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const Checkmark = styled('label')`
    display: block;
    width: 30px;
    height: 30px;
    background-color: #cbd2d9;
    border-radius: 10px;
    position: relative;
    transition: background-color 0.4s;
    overflow: hidden;
    cursor: pointer;

    ${props => props.checked && `background-color: #2d915a;`}

    &::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 10px;
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        top: 44%;
        left: 50%;
        transform: translate(-50%, -50%) rotateZ(40deg) scale(10);
        opacity: 0;
        transition: all 0.4s;
    }

    ${props =>
        props.checked &&
        `
            &::after {
                opacity: 1;
                transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
            }
        `}
`;

const Checkbox = ({ option, setSpec, label, defaultValue, ...rest }) => {
    const [checked, setChecked] = useState(defaultValue);

    const handleChange = () => {
        setChecked(!checked);

        if (option === 'startWithLorem') {
            setSpec({ ...rest, startWithLorem: !checked });
        }

        if (option === 'withHtml') {
            setSpec({ ...rest, withHtml: !checked });
        }
    };

    return (
        <CheckboxWrapper className="checkbox-wrapper">
            <input hidden id={option} type="checkbox" onChange={handleChange} />
            <span>{label}</span>

            <Checkmark checked={checked} className="checkmark" for={option} />
        </CheckboxWrapper>
    );
};

Checkbox.propTypes = {
    defaultValue: PropTypes.bool,
    label: PropTypes.string,
    option: PropTypes.string,
    setSpec: PropTypes.func,
};

export default Checkbox;
